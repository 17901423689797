/* General Layout */
.container {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

/* Video Upload Section */
.file-input {
  display: block;
  margin: 0 auto 20px;
}

.video-section {
  flex: 1;
  margin-right: 20px;
}

.video-preview {
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto 20px;
}

.upload-button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.upload-button:hover {
  background-color: #0056b3;
}

/* Description Section */
.description-section {
  flex: 1;
  margin-left: 20px;
}

.description {
  margin-top: 20px;
}

.markdown-content {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 100%;
  box-sizing: border-box;
}

/* Prompt Buttons and Text Area */
.prompt-buttons {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.prompt-button {
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
}

.prompt-button:hover {
  background-color: #0056b3;
}

.custom-prompt {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  min-height: 100px;
  resize: vertical;
}

/* Markdown Content Styling */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3 {
  margin-top: 20px;
  color: #333;
}

.markdown-content p {
  margin-bottom: 10px;
  line-height: 1.6;
}

.markdown-content ul {
  padding-left: 20px;
  list-style-type: disc;
}

.markdown-content ul li {
  margin-bottom: 5px;
}

.markdown-content blockquote {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #eee;
  border-left: 5px solid #ccc;
}

.markdown-content code {
  font-family: 'Courier New', Courier, monospace;
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 3px;
}

input, textarea {
  outline: none;
  font-size: 16px; /* Prevent zoom on iOS */
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  
  .video-section,
  .description-section {
    margin: 0;
    width: 100%;
  }
  
  .video-preview {
    margin-bottom: 20px;
  }
}
